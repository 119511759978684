import axios from "axios";

export const showDetail = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/showDetail',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const userFirst = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/userFirst',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const addOrUpdateDetail = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/addOrUpdateDetail',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const deleteDetail = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/deleteDetail',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const addOrUpdate = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/addOrUpdate',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const watchDelete = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/delete',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const getWatchList = (data) => {
  return axios({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/getList',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}
