import md5 from 'js-md5';

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export function formatTime(date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  const milliSecond = date.getMilliseconds()
  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':') +
    '.' + milliSecond
}

export function getSignAndTime(userInfo) {
  var timestamp = formatTime(new Date())
  var sign = "UserName=" + userInfo.UserName;
  sign += "&_timestamp=" + timestamp;
  sign += "&UserId=" + userInfo.UserId;
  sign += "&CorpId=" + userInfo.CorpId;
  sign += "&_huishang";
  sign = md5(sign).toLocaleUpperCase()
  sign = md5(sign).toLocaleUpperCase()
  sign = md5(sign).toLocaleUpperCase()
  return { sign, timestamp }
}

export function isPrice(str) {
  return /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(str)
}

// 数据过滤，时间过滤
export function formatJson (filterVal, jsonData) {
	return jsonData.map(v => filterVal.map(j => {
		if (j === "timestamp") {
		  return parseTime(v[j])
		} else {
		  return v[j]
		}
	}))
}
/**
 * 多级获取数据
 * @param key 用点分开
 * @param data 数据对象
 */
export function getData(key, data) {
    if (data != null && key) {
        if (key.indexOf('.') > -1) {
            let keys = key.split('.');
            let nextKey = key.substr(keys[0].length + 1);
            let currKey = keys[0];
            if (currKey.indexOf('[') > -1) {
                return getData(nextKey, getData(currKey, data))
            }
            return getData(nextKey, data[keys[0]]);
        } else if (key.indexOf('[') > -1) {
            let currKeyPre = key.substr(0, key.indexOf('['));
            let indexStart = key.indexOf('[') + 1;
            let i = Number(key.substr(indexStart, key.length - indexStart - 1));
            let d = data[currKeyPre];
            if (d instanceof Array) {
                return d[i];
            }
            return d;
        } else {
            return data[key];
        }
    }
}

export function setData(key, data, value) {
    if (key != null) {
        if (key.indexOf(".") > -1) {
            let keys = key.split('.');
            let nextKey = key.substr(keys[0].length + 1);
            let currKey = keys[0];
            if (currKey.indexOf('[') > -1) {
                let d = getData(currKey, data);
                if (!d) {
                    d = setData(currKey, data, nextKey ? {} : value);
                }
                return setData(nextKey, d, value)
            }
            if (!data[keys[0]]) {
                data[keys[0]] = {};
            }
            return setData(nextKey, data[keys[0]], value);
        } else if (key.indexOf('[') > -1) {
            let currKeyPre = key.substr(0, key.indexOf('['));
            let indexStart = key.indexOf('[') + 1;
            let i = Number(key.substr(indexStart, key.length - indexStart - 1));
            if (!data[currKeyPre]) {
                data[currKeyPre] = [];
            }
            if (data[currKeyPre] instanceof Array) {
                if (data[currKeyPre].length < i) {
                    for (let j = data[currKeyPre].length; j <= i; j++) {
                        data[currKeyPre][j] = {};
                    }
                }
                data[currKeyPre][i] = value;
                return data[currKeyPre][i];
            }
            data[currKeyPre] = value;
            return data;
        } else {
            data[key] = value;
            return data;
        }
    }
}

export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}
//根据区域编号转换区域
export function formatAreaCode(areaCode,arr){
	return new Promise(resolve=>{
		if(!areaCode)resolve({})
		else{
			let res={}
			const len=areaCode.length//几位数
			const province=areaCode.substring(0,2)//省
			let city=''//市
			let provinceArr=len==2?filterArea(arr,province+'0000'):arr//省对象
			let cityArr={}//市对象
			
			if(len==4 || len==6){
				city=areaCode.substring(0,4)//市
				cityArr=filterArea(provinceArr.children,city+'00')
			}
			
			switch(len){
				case 4:{
					res=cityArr//市对象
				}break;
				case 6:{
					res=cityArr.length==0?[]:filterArea(cityArr[0].children,areaCode)//区对象
				}break;
				default:{
					res=provinceArr
				}break;
			}
			resolve(res)
		}
	})
}
//根据层级数筛选指定区域编号的数据，返回区域对象
function filterArea(arr,val){
	if(arr.length>0){
		return arr.filter((m)=>{
			return m.AreaId===val
		})
	}else{
		return []
	}
}
//下载图片
export function downloadPic(href, name) {
  let eleLink = document.createElement('a')
  eleLink.download = name
  eleLink.href = href
  eleLink.click()
  eleLink.remove()
}